var teamName;
var teamId;

window.EditTeam = {
    init(name = "", id = "") {
        teamName = name;
        teamId = id;
        this.validateTeamForm();
        this.handleDescriptionKeyup();
        this.handleClickEvents();
    },

    descriptionCounter() {
        var characterCount = $(this).val().length,
            current = $('#description-counter');
        current.text(characterCount);
    },

    handleDescriptionKeyup() {
        $('#team-details-form textarea[name="team[description]"]').on('keyup', this.descriptionCounter);
        $('#team-details-form textarea[name="team[description]"]').trigger('keyup');
    },

    validateTeamForm() {
        $('#team-details-form').validate({
            onsubmit: true,
            onkeyup: false,
            onclick: false,
            onfocusout: false,
            rules: {
                "team[name]": {
                    required: true,
                    minlength: 2,
                    maxlength: 50
                },
                "team[description]": {
                    required: true,
                    minlength: 10,
                    maxlength: $('#team-details-form textarea[name="team[description]"]').maxlength
                }
            },
            errorPlacement: (error, element) => {
                error.insertAfter(element);
            },
            onfocusout: function (element) {
                return $(element).valid();
            }
        });
    },

    handleClickEvents() {
        document.addEventListener("DOMContentLoaded", function () {
            var removeButtons = document.querySelectorAll("#remove-user-from-team");
            removeButtons.forEach(function (button) {
                button.addEventListener("click", function () {
                    var userId = $(this).attr("data-user-id");
                    var userName = $(this).attr("data-user-name");
                    var action = $(this).attr("data-action");
                    EditTeam.showRemoveUserPopup(userId, userName, action);
                });
            });
        });

        document.addEventListener("DOMContentLoaded", function () {
            var removeButtons = document.querySelectorAll("#nominate-user-for-team");
            removeButtons.forEach(function (button) {
                button.addEventListener("click", function () {
                    var userId = $(this).attr("data-user-id");
                    var userName = $(this).attr("data-user-name");
                    EditTeam.showNominateUserForTeamPopup(userId, userName);
                });
            });
        });

        document.addEventListener("DOMContentLoaded", function () {
            var removeButtons = document.querySelectorAll("#denominate-user-for-team");
            removeButtons.forEach(function (button) {
                button.addEventListener("click", function () {
                    var userId = $(this).attr("data-user-id");
                    var userName = $(this).attr("data-user-name");
                    EditTeam.showDenominateUserForTeamPopup(userId, userName);
                });
            });
        });

        document.addEventListener("DOMContentLoaded", function () {
            $("#action-user-modal-btn").click(function () {
                let userId = $(this).attr("data-user-id");
                let action = $(this).attr("data-action");

                switch (action) {
                    case 'remove':
                        EditTeam.removeUserFromTeam(userId, teamId);
                        break;
                    case 'nominate':
                        EditTeam.nominateUserForTeam(userId, teamId, true);
                        break;
                    case 'denominate':
                        EditTeam.nominateUserForTeam(userId, teamId, false);
                        break;
                }
            });
        });

        document.addEventListener('DOMContentLoaded', function () {
            const editTeamDetailsButton = document.getElementById('edit-team-details-btn');
            const saveTeamDetailsButton = document.getElementById('save-team-details-btn');
            const nameInput = document.querySelector('#team-details-card input[name="team[name]"]');
            const descInput = document.querySelector('#team-details-card textarea[name="team[description]"]');
            const categoriesInput = document.querySelector('#team-details-card select[name="team[industry_ids][]"]');

            editTeamDetailsButton.style.display = 'block';
            saveTeamDetailsButton.style.display = 'none';

            editTeamDetailsButton.addEventListener('click', function () {
                nameInput.disabled = !nameInput.disabled;
                descInput.disabled = !descInput.disabled;
                categoriesInput.disabled = !categoriesInput.disabled;
                tagsSelect.disabled = !tagsSelect.disabled;

                editTeamDetailsButton.style.display = 'none';
                saveTeamDetailsButton.style.display = 'block';
            });
        });
    },

    removeUserFromTeam(userId, teamId) {
        $.ajax({
            type: "POST",
            url: '/teams/' + teamId + '/remove_users',
            data: {
                user_ids: [userId]
            },
            dataType: 'json',
            success: function () {
                window.location.reload();
            },
            error: function () {
                window.location.reload();
            }
        });
    },

    nominateUserForTeam(userId, teamId, nominate) {
        $.ajax({
            type: "PATCH",
            url: '/teams/' + teamId + '/nominate_user',
            data: {
                user_id: userId,
                nominate: nominate
            },
            dataType: 'json',
            success: function () {
                window.location.reload();
            },
            error: function () {
                window.location.reload();
            }
        });
    },

    showRemoveUserPopup(userId, userName) {
        let modalTitleHtml = "Are you sure you want to remove " + userName + " from " + teamName + "?";
        $('#action-user-modal-title').html(modalTitleHtml);
        let modalBodyHtml = "";
        $('#action-user-modal-body').html(modalBodyHtml);
        const actionBtn = document.getElementById("action-user-modal-btn");
        actionBtn.dataset.userId = userId;
        actionBtn.dataset.action = 'remove';
        $(actionBtn).html("Remove user");
        $("#action-user-modal").modal('show');
    },

    showNominateUserForTeamPopup(userId, userName) {
        let modalTitleHtml = "Nominate " + userName + " for " + teamName + "?";
        $('#action-user-modal-title').html(modalTitleHtml);

        let modalBodyHtml = "Nominating a user adds them as a point of contact for " + teamName + ". " +
            "They will receive notifications about general messages, new connections etc." +
            "<br><br> A team can have multiple nominated users, but must have at least one.";
        $('#action-user-modal-body').html(modalBodyHtml);
        const actionBtn = document.getElementById("action-user-modal-btn");
        actionBtn.dataset.userId = userId;
        actionBtn.dataset.action = 'nominate';
        $(actionBtn).html("Nominate user");
        $("#action-user-modal").modal('show');
    },

    showDenominateUserForTeamPopup(userId, userName) {
        let modalTitleHtml = "Remove nomination for " + userName + " for  " + teamName + "?";
        $('#action-user-modal-title').html(modalTitleHtml);

        let modalBodyHtml = "Removing a user nomination removes them as a point of contact for " + teamName + ". " +
            "They will no longer receive notifications about general messages, new connections etc." +
            "<br><br> A team can have multiple nominated users, but must have at least one.";
        $('#action-user-modal-body').html(modalBodyHtml);
        const actionBtn = document.getElementById("action-user-modal-btn");
        actionBtn.dataset.userId = userId;
        actionBtn.dataset.action = 'denominate';
        $(actionBtn).html("Remove nomination");
        $("#action-user-modal").modal('show');
    },

    savePrivateTeamDetails() {

    },
};
