import * as Sentry from "@sentry/browser";

const CONFIGS_ENDPOINT = '/configs/fetch_env_vars';
const SENTRY_DSN_PARAM = 'SENTRY_DSN';
const RAILS_ENV_PARAM = 'RAILS_ENV';

export async function initializeSentry() {
    try {
        window.sentry = await initializeSentryInstance();
        console.log("Sentry initialized.");
        document.dispatchEvent(new Event('sentryReady'));
    } catch (error) {
        console.error("Error initializing sentry:", error);
    }
}

async function initializeSentryInstance() {
    try {
        const envVars = await fetchSentryEnvVars();
        const sentryDsn = envVars[SENTRY_DSN_PARAM];
        const environment = envVars[RAILS_ENV_PARAM];
        if (sentryDsn && environment) {
            return Sentry.init({
                dsn: sentryDsn,
                environment: environment,
                integrations: [
                    Sentry.browserTracingIntegration(),
                    Sentry.replayIntegration(),
                ],
                enableTracing: true,
                tracesSampleRate: 1.0,
                profilesSampleRate: 1.0,
                breadcrumbsLogger: ["console", "sentry"],
                sendDefaultPii: true,
                includeLocalVariables: true,
                enabledEnvironments: ["development", "staging", "production", "prelive", "demo", "qa"],
                loggerLevel: "info",
                debug: (environment === "development"),
            });
        } else {
            console.error("SENTRY_DSN or RAILS_ENV is not set.");
        }
    } catch (error) {
        console.error("Error fetching sentry env vars:", error);
    }
}

async function fetchSentryEnvVars() {
    try {
        const response = await $.ajax({
            url: CONFIGS_ENDPOINT,
            method: 'GET',
            data: {keys: [SENTRY_DSN_PARAM, RAILS_ENV_PARAM]},
            dataType: 'json'
        });
        return response || {};
    } catch (error) {
        console.error("Failed to fetch sentry env vars:", error);
        throw new Error("Failed to fetch sentry env vars");
    }
}
