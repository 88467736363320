window.SectorsNTags = {
    init() {
        this.handleButtonClicks();
    },

    handleButtonClicks() {
        $("#submit-sectors-n-tags").click(async function () {
            try {
                await SectorsNTags.submitCompanyIndustries();
                await SectorsNTags.submitCompanyInterestedSectors();
                await SectorsNTags.submitCompanyTags();
                window.location.reload();
            } catch (error) {
                console.error("An error occurred while processing the requests:", error);
                sentry.captureException(error);
                window.location.reload();
            }
        });
    },

    submitCompanyIndustries() {
        return new Promise((resolve, reject) => {
            var categoryIds = document.getElementById('operating_category_ids');
            var companyId = document.getElementById('company_id').value;
            var selectedCategories = [];
            for (var i = 0; i < categoryIds.children.length; i++) {
                if (categoryIds.children[i].selected) {
                    selectedCategories.push(categoryIds.children[i].value);
                }
            }

            var companyIndustryIds = document.getElementById("company_industry_ids").value.split(" ");
            var removedCategories = companyIndustryIds.filter(value => !selectedCategories.includes(value));

            if (selectedCategories.length > 0) {
                $.ajax({
                    url: `/company_industries`,
                    type: 'POST',
                    data: {
                        company_id: companyId,
                        categories: selectedCategories
                    },
                    success: resolve,
                    error: reject
                });
            }

            if (removedCategories.length > 0) {
                $.ajax({
                    url: `/company_industries/destroy_multiple`,
                    type: 'DELETE',
                    data: {
                        company_id: companyId,
                        categories: removedCategories
                    },
                    success: resolve,
                    error: reject
                });
            }
        });
    },

    submitCompanyInterestedSectors() {
        return new Promise((resolve, reject) => {
            var categoryIds = document.getElementById('interested_category_ids');
            var companyId = document.getElementById('company_id').value
            var selectedCategories = [];
            for (var i = 0; i < categoryIds.children.length; i++) {
                if (categoryIds.children[i].selected) {
                    selectedCategories.push(categoryIds.children[i].value);
                }
            }

            var companyInterestedSectorIds = document.getElementById("company_interested_sector_ids").value.split(" ");
            var removedCategories = companyInterestedSectorIds.filter(value => !selectedCategories.includes(value));

            if (selectedCategories.length > 0) {
                $.ajax({
                    url: `/company_interested_sectors`,
                    type: 'POST',
                    data: {
                        company_id: companyId,
                        categories: selectedCategories
                    },
                    success: resolve,
                    error: reject
                });
            }

            if (removedCategories.length > 0) {
                $.ajax({
                    url: `/company_interested_sectors/destroy_multiple`,
                    type: 'DELETE',
                    data: {
                        company_id: companyId,
                        categories: removedCategories
                    },
                    success: resolve,
                    error: reject
                });
            }
        });
    },

    submitCompanyTags() {
        return new Promise((resolve, reject) => {
            var tagsSelect = document.getElementById('tags');
            var tagValues = tagsSelect.value.split(",");
            var companyUuid = document.getElementById('company_uuid').value

            $.ajax({
                url: '/save-tags',
                method: 'POST',
                data: {
                    uuid: companyUuid,
                    tags: tagValues
                },
                success: resolve,
                error: reject
            });
        });
    },
}