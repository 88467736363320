window.AiMatches = {
    init() {
        this.handleClickEvents();
        this.handleCheckboxChangeEvents();
        this.handleFocusEvents();
        this.handleShowAllEvents();

        columnSelectCheckboxes.forEach((checkbox) => {
            const columnKey = checkbox.dataset.key;
            localStorage.removeItem(columnKey);

            const columnIndex = checkbox.dataset.index;
            checkbox.checked ? showColumn(columnIndex) : hideColumn(columnIndex);
        });

        updateContactSelectedButtonState();
    },

    handleClickEvents() {
        contactSelectedBtn.addEventListener('click', function () {
            const selectedMatchIds = selectedMatches.map(match => match.id);
            contactSupplier(selectedMatchIds);
        });

        positiveSelectedBtn.addEventListener('click', function () {
            const selectedMatchIds = selectedMatches.map(match => match.id);
            updateRating(selectedMatchIds, 1);
        });

        negativeSelectedBtn.addEventListener('click', function () {
            const selectedMatchIds = selectedMatches.map(match => match.id);
            updateRating(selectedMatchIds, -1);
            matchesCheckboxes.forEach(function (checkbox) {
                checkbox.checked = false
            });
            selectAllMatchesCheckbox.checked = false;
            updateContactSelectedButtonState();
        });
    },

    handleCheckboxChangeEvents() {
        selectAllMatchesCheckbox.addEventListener('change', function () {
            matchesCheckboxes.forEach(function (checkbox) {
                checkbox.checked = selectAllMatchesCheckbox.checked;
            });
            updateContactSelectedButtonState();
        });

        matchesCheckboxes.forEach(function (checkbox) {
            checkbox.addEventListener('change', function () {
                selectAllMatchesCheckbox.checked = Array.from(matchesCheckboxes).every(checkbox => checkbox.checked);
                updateContactSelectedButtonState();
            });
        });

        columnSelectCheckboxes.forEach((checkbox) => {
            const columnKey = checkbox.dataset.key;
            const columnIndex = checkbox.dataset.index;

            const savedState = localStorage.getItem(columnKey);
            if (savedState !== null) {
                const isChecked = savedState === 'true';
                checkbox.checked = isChecked;
                isChecked ? showColumn(columnIndex) : hideColumn(columnIndex);
            }

            checkbox.addEventListener('change', (event) => {
                const elm = event.currentTarget;
                const columnKey = elm.dataset.key;
                const columnIndex = elm.dataset.index;
                localStorage.setItem(columnKey, elm.checked);
                elm.checked ? showColumn(columnIndex) : hideColumn(columnIndex);
            });
        });
    },

    handleFocusEvents() {
        matchNotes.on('focusout', function () {
            const aiMatchId = $(this).data('ai-match-id');
            const notes = $(this).val();
            $.ajax({
                url: MATCH_URL + aiMatchId,
                type: 'PATCH',
                data: {data_system_deal_match: {notes: notes}}
            });
        });
    },

    handleShowAllEvents() {
        this.handleShowAll('emails');
        this.handleShowAll('phone-numbers');
        this.handleShowAll('products');
    },

    handleShowAll(listType) {
        const lists = document.querySelectorAll(`.${listType}-list`);
        const aiMatchIds = [];
        lists.forEach((list) => {
            if (list.dataset.aiMatchId) { // Check if the attribute exists
                aiMatchIds.push(list.dataset.aiMatchId);
            }
        });

        aiMatchIds.forEach(aiMatchId => {
            showAll(`show-all-${listType}-${aiMatchId}`, `${listType}-list-${aiMatchId}`);
        });
    },

    openManualMatchModal() {
        $('#create-manual-match-modal').modal('show');
    }
};

const MATCH_URL = '/ai_matches/';
const matchNotes = $('.match-notes');

const positiveColor = 'rgba(128, 255, 128, 0.3)'; // 0.3 translates to "4d" opacity in hex
const negativeColor = 'rgba(255, 128, 128, 0.3)';

const selectAllMatchesCheckbox = document.getElementById('select-all-matches');
const matchesCheckboxes = document.querySelectorAll('input[type="checkbox"].match-select');
const columnSelectCheckboxes = document.querySelectorAll('input[type="checkbox"].column-select');
const contactSelectedBtn = document.getElementById('contact-matches-btn');
const positiveSelectedBtn = document.getElementById('positive-rate-matches-btn');
const negativeSelectedBtn = document.getElementById('negative-rate-matches-btn');

let selectedMatches = [];

const updateRating = (aiMatchIds, rating) => {
    aiMatchIds.forEach(aiMatchId => {
        let tableRow = $(`tr[data-ai-match-id="${aiMatchId}"]`)
        let tableRowData = document.querySelectorAll(`td[data-ai-match-id="${aiMatchId}"].border`)
        let checkedEmails = selectCheckedEmails(tableRow, rating);

        $.ajax({
            url: MATCH_URL + aiMatchId,
            type: 'PATCH',
            data: {data_system_deal_match: {rating: rating, emails: checkedEmails}},
            success: function (data) {
                if (rating === 1) {
                    tableRowData.forEach((cell) => {
                        $(cell).css('background-color', `${positiveColor}`);
                    })
                    tableRow.find('input:checked.email-select').parent().css('background-color', `${positiveColor}`);
                    tableRow.find('input:checkbox:not(:checked).email-select').parent().css('background-color', `${negativeColor}`);
                } else {
                    tableRowData.forEach((cell) => {
                        $(cell).css('background-color', `${negativeColor}`);
                    })
                    tableRow.find('input.email-select').parent().css('background-color', `${negativeColor}`);
                }
            }
        });
    })
};

const selectCheckedEmails = (table_row, rating) => {
    let emails = [];
    if (rating === 1) {
        emails = table_row.find('input:checked').map(function (_, el) {
            return $(el).val();
        }).get();
    } else {
        emails = table_row.find('input').map(function (_, el) {
            return $(el).val();
        }).get();
    }
    return emails;
};

const contactSupplier = (aiMatchIds) => {
    $.ajax({
        url: MATCH_URL + 'contact_suppliers',
        type: 'POST',
        data: {
            data_system_deal_match_ids: aiMatchIds
        },
        success: function (data) {
            window.location.reload();
        },
        failure: function (error) {
            window.location.reload();
        }
    });
};

const updateContactSelectedButtonState = () => {
    let anyMatchSelected = false;
    selectedMatches = [];

    matchesCheckboxes.forEach(function (checkbox) {
        if (checkbox.checked) {
            anyMatchSelected = true;
        }

        if (checkbox.checked && checkbox !== selectAllMatchesCheckbox) {
            const match = {"id": checkbox.dataset.aiMatchId};
            selectedMatches.push(match);
        }
    });

    const selectedCountDesc = document.getElementById('selected-count-desc');
    selectedCountDesc.textContent = `${selectedMatches.length} of ${matchesCheckboxes.length}`;
    contactSelectedBtn.disabled = !anyMatchSelected;
    positiveSelectedBtn.disabled = !anyMatchSelected;
    negativeSelectedBtn.disabled = !anyMatchSelected;
}

function hideColumn(index) {
    $(`td:nth-child(${index}), th:nth-child(${index})`).hide();
}

function showColumn(index) {
    $(`td:nth-child(${index}), th:nth-child(${index})`).show();
}

function showAll(showAllId, listId) {
    const showAllBtn = document.getElementById(showAllId);
    const elementList = document.getElementById(listId);

    if (elementList) {
        Array.from(elementList.children).forEach((item, index) => {
            if (index > 2) {
                item.style.display = 'none';
            } else {
                item.style.display = 'block';
            }
        });

        if (showAllBtn) {
            showAllBtn.dataset.showingAll = "false";
            showAllBtn.addEventListener('click', () => {
                const isShowingAll = showAllBtn.dataset.showingAll === "true";

                if (isShowingAll) {
                    Array.from(elementList.children).forEach((item, index) => {
                        if (index > 2) {
                            item.style.display = 'none';
                        } else {
                            item.style.display = 'block';
                        }
                    });

                    showAllBtn.textContent = 'Show All';
                    showAllBtn.dataset.showingAll = "false";
                } else {
                    Array.from(elementList.children).forEach((item) => {
                        item.style.display = 'block';
                    });

                    showAllBtn.textContent = 'Show Less';
                    showAllBtn.dataset.showingAll = "true";
                }
            });
        }
    }
}
