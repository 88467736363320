const { ajax } = require("jquery");

window.Company = {
  init(companyId) {
    this.handleReportCompanyOnClick(companyId);
  },

  handleReportCompanyOnClick(companyId) {
    $('#btnReportCompany').on('click', function(e) {
      $.ajax({
        url: `/company/report`,
        type: 'POST',
        data: { id: companyId },
        success: (data) => {
          $('.company-header').before(
            '<div class="alert alert-color text-center alert-dismissable" id="rr-success-alert">'+
                '<button type="button" class="close" ' +
                  'data-dismiss="alert" aria-hidden="true">' +
                    '&times;' +
                '</button>' +
                data['success'] +
            '</div>'
          );
          $("#rr-success-alert").fadeTo(5000, 500).slideUp(500, function() {
            $("#rr-success-alert").slideUp(500);
          });
          setTimeout(function() {
            $("#rr-success-alert").remove();
          }, 6000);
        },
        failure: (error) => {
          alert('Failed!');
        }
      });
    });
  }
};
